import { IMAGE_SERVER_URL_DEV } from "Constant/System";


export const EMAIL_TEMPLATE = ({ customerName, invoiceNo, pdfName, deliverydate }) => {
  return (
   ` <html lang="en">
      <head>
        <meta charset="utf-8" />
        <title>{pdfName}</title>
        <meta name="viewport" content="initial-scale=1.0; maximum-scale=1.0; width=device-width;" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
        <style>
          body {
            background-color: #f4f4f4;
            font-family: 'Inter', sans-serif;
            color: #000;
          }
          .container {
            background-color: #ffffff;
            max-width: 800px;
            margin: 0 auto;
            padding: 40px;
            border: 1px solid #e0e0e0;
          }
          .logo {
            text-align: left;
            margin-bottom: 40px;
          }
          .logo img {
            width: 220px;
          }
          .content {
            font-size: 16px;
            line-height: 1.6;
          }
          .content h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
          }
          .content p {
            margin-bottom: 20px;
          }
          .footer {
            margin-top: 40px;
            font-size: 14px;
            color: #777;
          }
          .footer p {
            margin: 5px 0;
          }
          .highlight {
            font-weight: 600;
            color: #ef7e2e;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="logo">
            <img src="${IMAGE_SERVER_URL_DEV}/uploads/logo/logo.png" alt="Silvermill Logo" />
          </div>
          <div class="content">
            <h1>Hello ${customerName}</h1>
            <p>
              Thank you for your payment. We are pleased to confirm your Silvermill product order 
              <strong>(No: ${invoiceNo})</strong>. Your order is scheduled for delivery on 
              <strong>${deliverydate}</strong>.
            </p>
            <p>
              If you have any questions or need further assistance, please feel free to reach us at 
              (+94) 11 7389 376/77 during our business hours: weekdays from 9 am to 5 pm.
            </p>
            <p>We appreciate your business and look forward to serving you again.</p>
          </div>
          <div class="footer">
            <p>Thank you for choosing Silvermill.</p>
            <p>Best regards,</p>
            <p>Silvermill Team</p>
          </div>
        </div>
      </body>
    </html>`
  );
};
