import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink, useHistory } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2, NAVIGATION_DEMO_3 } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import logoImg from "images/logo-big.png";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
  userD?: any;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_3,
  onClickClose,
  userD
}) => {
  const history = useHistory();

  const _renderMenuChild = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    console.log("BBBB", data);
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-sm">
        {item.categories?.map((i: any, index: any) => (
          <Disclosure key={i.href + index} as="li">
            <div
              onClick={() => {
                localStorage.setItem("sMain", i.id);
                history.push("/shop");
                window.location.reload();
              }}
            >
              <NavLink
                // exact
                // strict
                to={{
                  pathname: i.href || undefined
                }}
                className={`flex text-xs rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                activeClassName="text-secondary"
              >
                <span
                  className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                  onClick={onClickClose}
                >
                  {i.name}
                </span>
                {/* {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-slate-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )} */}
              </NavLink>
            </div>
            {/* {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )} */}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderMenuChild2 = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-sm">
        {item.children?.map((i: any, index: any) => (
          <Disclosure key={i.href + index} as="li">
            {i.target === true ? (
              <>
                <a
                  href={i.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex text-xs rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                >
                  <span
                    className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                    onClick={onClickClose}
                  >
                    {i.name}
                  </span>
                  {i.children && (
                    <span
                      className="flex items-center flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-slate-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )}
                </a>
              </>
            ) : (
              <NavLink
                exact
                strict
                to={{
                  pathname: i.href || undefined
                }}
                className={`flex text-xs rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                activeClassName="text-secondary"
              >
                <span
                  className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                  onClick={onClickClose}
                >
                  {i.name}
                </span>
                {i.children && (
                  <span
                    className="flex items-center flex-grow"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Disclosure.Button
                      as="span"
                      className="flex justify-end flex-grow"
                    >
                      <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-slate-500"
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                  </span>
                )}
              </NavLink>
            )}

            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: any, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 dark:text-white"
      >
        {item.name !== "Hire" ? (
          <>
            <NavLink
              //  href={item.href}
              exact
              strict
              className="flex font-berthold w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-base hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              to={{
                pathname: item.href || undefined
              }}
              activeClassName="text-secondary"
            >
              <span
                className={!item.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {item.name}
              </span>
              {item.categories && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
              {/* {
                  index !== 0 && item.children && (
                    <span
                      className="block flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-neutral-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )
                } */}
            </NavLink>
          </>
        ) : (
          <>
            <a
              href={item.href}
              // exact
              // strict
              className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-xs hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
            >
              <span
                className={!item.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {item.name}
              </span>
              {item.categories && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
              {/* {
                  index !== 0 && item.children && (
                    <span
                      className="block flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-neutral-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )
                } */}
            </a>
          </>
        )}

        {item.categories && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
        {index !== 0 && item.children && (
          <Disclosure.Panel>{_renderMenuChild2(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full z-[999] h-screen py-2 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <img src={logoImg} className="w-[250px]" alt="" />
        {/* <Logo sideMenu /> */}
        <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-xs">
          {/* <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span> */}

          {/* <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-lg" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div> */}
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>
      <ul className="flex flex-col text-base text-slate-900 font-berthold py-6 px-2 space-y-1">
        {data.map(_renderItem)}
        {userD !== "" && userD === "none" ? (
          <>
            <a
              href="/register"
              // exact
              // strict
              className="flex w-full items-center text-base py-2.5 px-4 font-medium uppercase tracking-wide text-xs hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
            >
              <span className="block text-base w-full" onClick={onClickClose}>
                Register
              </span>
            </a>
            <a
              href="/login"
              // exact
              // strict
              className="flex w-full items-center text-base py-2.5 px-4 font-medium uppercase tracking-wide text-xs hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
            >
              <span className="block text-base w-full" onClick={onClickClose}>
                Login
              </span>
            </a>
          </>
        ) : (
          <>
            <a
              href="/account"
              // exact
              // strict
              className="flex w-full items-center text-base py-2.5 px-4 font-medium uppercase tracking-wide text-xs hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
            >
              <span className="block text-base w-full" onClick={onClickClose}>
                Account
              </span>
            </a>
            <a
              href="/"
              // exact
              // strict
              className="flex w-full items-center text-base py-2.5 px-4 font-medium uppercase tracking-wide text-xs hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
            >
              <span
                className="block text-base w-full"
                onClick={() => {
                  console.log("logout>>>>");
                  window.localStorage.removeItem(
                    `${window.location.hostname}.login`
                  );
                  window.localStorage.removeItem("exprTime");
                  window.localStorage.clear();
                  // window.location.reload();

                  window.location.href = "/";

                  onClickClose?.();
                }}
              >
                Log out
              </span>
            </a>
          </>
        )}
      </ul>
      {/* <div className="flex items-center justify-between py-6 px-5 space-x-2">
        <ButtonPrimary href={"/"} className="!px-10">
          Buy this template
        </ButtonPrimary>
      </div> */}
    </div>
  );
};

export default NavMobile;
