import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { addToast } from "shared/Toast";
import heroimg from "images/hero-banner.png";
import productTitle from "images/productsTitle.png";
import pageBottom from "images/pagebottom.png";
import pageBottom2 from "images/pagebottom2.png";
import bannerjoin from "images/banner-join.png";
import pagetop from "images/page-top.png";
import pagetop2 from "images/page-top-2.png";
import notime from "images/no-time.png";
import save from "images/save.png";
import solution from "images/solution.png";
import solution3 from "images/solution3.png";
import solution2 from "images/solution2.png";
import arrowright from "images/arrowright.png";
import arrowleft from "images/arrowleft.png";
import reviewimg from "images/review-img.png";
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper as SwiperCore } from "swiper";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { CurrentUser, getAllProducts, getTestimonial } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import jwtDecode from "jwt-decode";
import { addToCard } from "Services/API/Post";
import NotifyAddTocart from "components/NotifyAddTocart";
import CartCount from "Constant/CartCount";
import CartContext from "Context/CartContext";
import toast from "react-hot-toast";
import { HomeProductSlider } from "components/HomeProductSlider";

function PageNewHome() {
  const [nProducts, setnProducts] = useState([]);
  const [reviews, setreviews] = useState([]);
  const [filterSortValue, setFilterSortValue] = useState("");
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(2000);
  const [selectedSub, setselectedSub] = useState<any>("");
  const [search, setsearch] = useState("");
  const [pageLimit, setpageLimit] = useState(68);
  const [currentPage, setCurrentPage] = useState(1);
  const [currUser, setcurrUser] = useState<any>("");

  const getItemProducts = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "unitPrice",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "unitPrice",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }

    // console.log(sortType)

    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId:
        selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });

          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  const getTestimonials = async () => {
    try {
      await getTestimonial()
        .then((response: any) => {
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);
          setreviews(response.data);
        })
        .catch(() => {
          console.log("T&C ");
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {
    getItemProducts();
    getTestimonials();
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="nc-PageHome relative overflow-hidden ">
      <Helmet>
        <title>Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href="/" />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Silvermill",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/"
}
    `}</script>
      </Helmet>
      {/* <div className="xl:w-full lg:h-[650px] 2xl:h-[768px]   "> */}
      <div className="xl:w-full   ">
        <img className=" xl:w-full   " src={heroimg} alt="" />
      </div>
      <div className="w-full flex flex-col">
        <img src={productTitle} alt="" />
      </div>
      <div className="relative flex w-full px-[5%] md:px-[10%] pt-[5%] pb-[5%]">
        {/* Custom Navigation Buttons */}
        {/* <div className="absolute top-1/2 left-[5%] md:left-[5%] z-10">
          <button className="prev-btn">
            <img src={arrowleft} className="w-8" alt="" />
          </button>
        </div>
        <div className="absolute top-1/2 right-[5%] md:right-[5%] z-10">
          <button className="next-btn">
            <img src={arrowright} className="w-8" alt="" />
          </button>
        </div> */}

        <HomeProductSlider nProducts={nProducts} currUser={currUser} />
      </div>
      <div className="flex w-full justify-center pb-[5%]">
        <Link
          to="/shop"
          className="font-berthold  w-fit px-6 py-3 md:mb-0 mb-10 text-2xl md:text-4xl  rounded-lg uppercase bg-[#010a4f] text-white"
        >
          Shop all products
        </Link>
      </div>

      <div className="w-full flex flex-col">
        <img src={pageBottom} alt="" />
      </div>

      <div className="w-full  ">
        <img className="w-full   " src={bannerjoin} alt="" />
      </div>
      <div className="w-full flex flex-col">
        <img src={pagetop} alt="" />
      </div>
      <CustomSwiper />
      <div className="w-full flex flex-col">
        <img src={pageBottom} alt="" />
      </div>
      <div className=" bg-[#010a4f] w-full flex flex-col items-center  py-10">
        <div className=" text-4xl md:text-8xl tracking-tight font-medium font-berthold text-[#ffe680] mt-14 uppercase">
          the solutions
        </div>
        <div className=" text-2xl md:text-5xl font-berthold text-white ">
          you've been looking for...
        </div>
        <div className="w-full px-[10%] pt-20 py-10 pb-3">
          <div className=" w-full md:flex hidden justify-between ">
            <div className="flex w-[30%] flex-col items-center justify-center bg-[#1b2464] rounded-lg overflow-hidden transition-transform  scale-[0.9] p-1 duration-300">
              <div
                className={` w-full h-full flex flex-col items-center  justify-center`}
              >
                <img src={solution2} alt="" />
              </div>
            </div>
            <div className="flex w-[30%] flex-col items-center justify-center bg-[#1b2464] rounded-lg transition-transform overflow-hidden scale-105  p-3 duration-300">
              <div
                className={` w-full h-full flex flex-col items-center justify-center `}
              >
                <img src={solution} alt="" />
              </div>
            </div>
            <div className="flex w-[30%] flex-col items-center justify-center bg-[#1b2464] rounded-lg transition-transform overflow-hidden duration-300  p-2 scale-[0.9]">
              <div
                className={` w-full h-full flex flex-col items-center  justify-center`}
              >
                <img src={solution3} alt="" />
              </div>
            </div>
          </div>
          <div className="w-full md:hidden">
            <Swiper
              spaceBetween={40}
              centeredSlides={true}
              loop={true}
              onSlideChange={(swiper: SwiperCore) =>
                setActiveIndex(swiper.realIndex)
              } // Explicitly type the parameter
              breakpoints={{
                640: { slidesPerView: 1 }, // 1 slide on mobile
                768: { slidesPerView: 2 }, // 2 slides on tablets
                1024: { slidesPerView: 3 } // 3 slides on desktop
              }}
              onSwiper={(swiper: SwiperCore) =>
                setActiveIndex(swiper.realIndex)
              } // Explicitly type the parameter
              className="flex justify-center items-center w-full "
            >
              {[1, 2, 3, 4].map((slideIndex) => (
                <SwiperSlide
                  key={slideIndex}
                  className={`w-[30%] custom-slide flex flex-col items-center justify-center bg-[#1b2464] rounded-lg transition-transform duration-300 ${
                    activeIndex === slideIndex ? "scale-110 z-10" : "scale-90"
                  }`}
                >
                  <div
                    className={` w-full h-full flex flex-col items-center justify-center`}
                  >
                    <img src={solution} alt="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Link
          to="/shop"
          className="mt-14 font-berthold tracking-wide w-fit px-5 py-3 text-xl md:text-3xl font-bold rounded-lg uppercase bg-white text-[#010a4f]"
        >
          Shop now
        </Link>
      </div>
      <div className="w-full bg-[#1b2464] flex flex-col">
        <img src={pagetop2} alt="" />
      </div>
      <div className="bg-[#1b2464] pb-5 pt-1 flex flex-col items-center">
        <div className=" text-2xl md:text-6xl font-berthold text-[#08afb2] ">
          Don't just take our word for it
        </div>
        <div className="text-4xl md:text-7xl font-berthold text-center md:text-start text-white uppercase">
          Here's what OUR CUSTOMERS SAY...
        </div>
        <div className=" flex flex-col w-full items-center px-[10%] pt-[5%] ">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            initialSlide={1}
            loop={true}
            autoplay={{
              delay: 3000, // 3 seconds between slides
              disableOnInteraction: false, // Continue autoplay after user interactions
            }}
            pagination={{
              clickable: true,
              el: ".custom-pagination"
            }}
            modules={[Pagination, Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 1 // 1 slide on mobile
              },
              768: {
                slidesPerView: 2 // 2 slides on tablets
              },
              1024: {
                slidesPerView: 3 // 3 slides on desktop
              }
            }}
            className="flex w-full text-white"
          >
            {reviews?.length > 0 &&
              reviews?.map((item: any, index) => {
                return (
                  <SwiperSlide className="w-[30%] flex ">
                    <img src={`${IMAGE_SERVER_URL_DEV}${item.image}`} alt="" />
                  </SwiperSlide>
                );
              })}

            {/* <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide> */}
          </Swiper>

          <div className="custom-pagination  flex justify-center space-x-3">
            {/* Pagination dots are automatically generated by Swiper */}
          </div>

          <div className="mt-5 font-justhand tracking-wide w-fit px-5 py-2 text-2xl md:text-4xl  rounded-lg   text-white">
            All comments are those made by Silvermill customers on our social
            media platforms
          </div>
        </div>
      </div>
      <div className="w-full flex bg-[#1b2464] flex-col">
        <img src={pageBottom2} alt="" />
      </div>
    </div>
  );
}

const ProductCard = ({ item, currUser }: { item: any; currUser?: any }) => {
  const [value, setValue] = useState<any | null>(0);
  const [lopen, setlopen] = React.useState(true);

  let discount = (
    ((parseFloat(item.mrp) - parseFloat(item.specialprice)) /
      parseFloat(item.mrp)) *
    100
  ).toString();

  useEffect(() => {
    setValue(1);
  }, [1]);
  const { cart, setCart } = useContext(CartContext);
  const handleClickDecrement = () => {
    if (1 >= value) return;
    setValue((state: number) => {
      return state - 1;
    });
  };
  const handleClickIncrement = () => {
    if (99 <= value) return;
    setValue((state: number) => {
      return state + 1;
    });
  };

  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id;
    }

    let error = false;
    let cartDetails = {
      productId: item?.id.toString(),
      userId: userId ? userId : tempId,
      qty: value,
      color: "",
      size: "",
      fileType: "",
      image: ""
    };

    addToCard(cartDetails)
      .then((data) => {
        setlopen(false);
        // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
        toast.custom(
          (t) => (
            <NotifyAddTocart
              productImage={`${IMAGE_SERVER_URL_DEV}${
                JSON.parse(item?.imgArray)[0].path
              }`}
              qualitySelected={value}
              show={t.visible}
              sizeSelected={""}
              variantActive={0}
              selectedProductColor={""}
              selectedProduct={item?.item}
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
        CartCount().then((res) => {
          setCart(res);
        });
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} Added to Cart`,
        //   open_toast: true,
        // });
      })
      .catch((err) => {
        setlopen(false);
        addToast(`${err.response.data.message}`, "warning");
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  };

  return (
    <div className="w-full flex flex-col items-center gap-2 justify-center ">
      {item?.specialprice && (
        <div className="absolute right-[-12%] z-[100] top-[-10%]">
          <div className="relative flex justify-center items-center w-[80px] h-[80px]">
            <img src={save} className="w-full" alt="" />
            <div className="absolute inset-0 flex items-center font-berthold pl-2 flex-col justify-center text-center text-white font-bold text-xs leading-tight transform rotate-[25deg] -translate-y-2">
              <div>SAVE</div>
              <div className="text-[18px]">{parseInt(discount)}%</div>
            </div>
          </div>
        </div>
      )}

      <div className="text-[#010a4f] font-berthold text-center text-2xl">
        {item?.productName}
      </div>
      {/* <div className="text-[#010a4f] font-berthold text-center text-2xl pb-3">
      500 ml
    </div> */}
      {item?.imgArray && (
        <img
          src={`${IMAGE_SERVER_URL_DEV}${JSON.parse(item?.imgArray)[0].path}`}
          className="  w-full aspect-1"
          alt=""
        />
      )}

      <div className=" w-full flex justify-center font-berthold text-[#010a4f] text-2xl items-end gap-2 pt-4">
        <div
          className={`${
            item?.specialprice && " text-[22px] text-[#8a8888] line-through "
          }`}
        >
          Rs.{item?.mrp}
        </div>
        {item?.specialprice && <div className="">Rs.{item?.specialprice}</div>}{" "}
        / Unit
      </div>
      <Link
        to={`/product-detail/${item?.productId}`}
        className={`font-berthold tracking-wider w-fit px-5 py-2 text-sm font-bold rounded-lg uppercase
           bg-[#010a4f] text-white`}
      >
        View Product
      </Link>
      {currUser && (
        <>
          <div className="flex gap-2 items-center mt-1">
            <div className="flex font-berthold text-[#010a4f] text-sm items-center rounded-md border-2 border-[#8a8888] px-3 py-1 gap-3">
              <button onClick={handleClickDecrement} disabled={1 >= value}>
                -
              </button>
              <span>{value}</span>
              <button className="text-[#010a4f]" onClick={handleClickIncrement}>
                +
              </button>
            </div>
            <button
              disabled={!currUser}
              onClick={notifyAddTocart}
              className={`font-berthold tracking-wider w-fit px-5 py-2 text-sm font-bold rounded-lg uppercase ${
                currUser ? "bg-[#02871ab2]" : "bg-[#02871a]"
              }  text-white`}
            >
              Add to cart
            </button>
          </div>
        </>
      )}
    </div>
  );
};

function CustomSwiper() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="px-[3%] pt-10 flex flex-col items-center ">
      <Swiper
        spaceBetween={30}
        slidesPerView={"auto"}
        loop={true}
        pagination={{
          clickable: true,
          el: ".custom-pagination-s" // Use the custom pagination element
        }}
        modules={[Pagination]}
        onSlideChange={(swiper: SwiperCore) => setActiveIndex(swiper.realIndex)} // Update active index
        className="flex w-full pb-10 "
      >
        <SwiperSlide>
          <img src={notime} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime} alt="Slide 3" />
        </SwiperSlide>
      </Swiper>

      {/* Custom Pagination */}
      <div className="custom-pagination-s mt-8 flex justify-center space-x-3">
        {[0, 1, 2].map((index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-3 h-3 rounded-full ${
              activeIndex === index ? "bg-[#010a4f]" : "bg-gray-300"
            }`}
          />
        ))}
      </div>

      <Link
        to="/shop"
        className="mt-16 mb-10 font-berthold tracking-wide w-fit px-5 py-2 text-4xl font-bold rounded-lg uppercase bg-[#010a4f] text-white"
      >
        Shop now
      </Link>
    </div>
  );
}

export default PageNewHome;
