import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { addToast } from "shared/Toast";
import heroimg from "images/hero-banner.png";
import recipiescoming from "images/recipiescoming.png";
import recipieshero from "images/recipieshero.png";
import notime from "images/no-time.png";
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper as SwiperCore } from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import { getAllProducts, getTestimonial } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import jwtDecode from "jwt-decode";
import { addToCard } from "Services/API/Post";
import NotifyAddTocart from "components/NotifyAddTocart";
import CartCount from "Constant/CartCount";
import CartContext from "Context/CartContext";
import toast from "react-hot-toast";

function PageRecipies() {
  const [nProducts, setnProducts] = useState([]);
  const [reviews, setreviews] = useState([]);
  const [filterSortValue, setFilterSortValue] = useState("");
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(2000);
  const [selectedSub, setselectedSub] = useState<any>("");
  const [search, setsearch] = useState("");
  const [pageLimit, setpageLimit] = useState(68);
  const [currentPage, setCurrentPage] = useState(1);

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.navigation.update();
    }
  }, [nProducts, swiperInstance]);

  const getItemProducts = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "unitPrice",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "unitPrice",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }

    // console.log(sortType)

    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId:
        selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  const getTestimonials = async () => {
    try {
      await getTestimonial()
        .then((response: any) => {
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);
          setreviews(response.data);
        })
        .catch(() => {
          console.log("T&C ");
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {
    getItemProducts();
    getTestimonials();
  }, []);

  const [activeIndex, setActiveIndex] = useState(2);
  return (
    <div className="nc-PageHome relative overflow-hidden ">
      <Helmet>
        <title>Silvermill | About Us</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href="/" />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Silvermill",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/"
}
    `}</script>
      </Helmet>
      {/* <div className="xl:w-full lg:h-[650px] 2xl:h-[768px]   "> */}
      <div className="xl:w-full   ">
        <img className=" xl:w-full   " src={recipieshero} alt="" />
      </div>
      <div className="xl:w-full   ">
        <img className=" xl:w-full   " src={recipiescoming} alt="" />
      </div>

     
    </div>
  );
}

const ProductCard = (item: any) => {
  const [value, setValue] = useState<any | null>(0);
  const [lopen, setlopen] = React.useState(true);
  useEffect(() => {
    setValue(1);
  }, [1]);
  const { cart, setCart } = useContext(CartContext);
  const handleClickDecrement = () => {
    if (1 >= value) return;
    setValue((state: number) => {
      return state - 1;
    });
  };
  const handleClickIncrement = () => {
    if (99 <= value) return;
    setValue((state: number) => {
      return state + 1;
    });
  };

  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id;
    }

    let error = false;
    let cartDetails = {
      productId: item?.item?.id.toString(),
      userId: userId ? userId : tempId,
      qty: value,
      color: "",
      size: "",
      fileType: "",
      image: ""
    };

    addToCard(cartDetails)
      .then((data) => {
        setlopen(false);
        // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
        toast.custom(
          (t) => (
            <NotifyAddTocart
              productImage={`${IMAGE_SERVER_URL_DEV}${
                JSON.parse(item?.item?.imgArray)[0].path
              }`}
              qualitySelected={value}
              show={t.visible}
              sizeSelected={""}
              variantActive={0}
              selectedProductColor={""}
              selectedProduct={item?.item}
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
        CartCount().then((res) => {
          setCart(res);
        });
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} Added to Cart`,
        //   open_toast: true,
        // });
      })
      .catch((err) => {
        setlopen(false);
        addToast(`${err.response.data.message}`, "warning");
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  };
  return (
    <div className="w-full flex flex-col items-center gap-2 justify-center ">
      <div className="text-[#010a4f] font-berthold text-center text-2xl">
        {item?.item?.productName}
      </div>
      {/* <div className="text-[#010a4f] font-berthold text-center text-2xl pb-3">
      500 ml
    </div> */}
      {item?.item?.imgArray && (
        <img
          src={`${IMAGE_SERVER_URL_DEV}${
            JSON.parse(item?.item?.imgArray)[0].path
          }`}
          className=" md:h-[300px] w-full"
          alt=""
        />
      )}

      <div className=" w-full flex justify-center font-berthold text-[#010a4f] text-3xl items-end gap-2 pt-4">
        <div
          className={`${
            item?.item?.specialprice && " text-2xl text-[#8a8888] line-through "
          }`}
        >
          Rs.{item?.item?.mrp}
        </div>
        {item?.item?.specialprice && (
          <div className="">Rs.{item?.item?.specialprice}</div>
        )}
      </div>
      <div className="flex font-berthold text-[#010a4f] text-lg items-center gap-2">
        <button onClick={handleClickDecrement} disabled={1 >= value}>
          -
        </button>
        <span>{value}</span>
        <button className="text-[#010a4f]" onClick={handleClickIncrement}>
          +
        </button>
      </div>
      <button
        onClick={notifyAddTocart}
        className=" font-berthold tracking-wider w-fit  px-5 py-3 text-2xl font-bold rounded-lg uppercase bg-[#02871a] text-white"
      >
        Add to cart
      </button>
    </div>
  );
};

function CustomSwiper() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="px-[8%] pt-10 flex flex-col items-center ">
      <Swiper
        spaceBetween={30}
        slidesPerView={"auto"}
        loop={true}
        pagination={{
          clickable: true,
          el: ".custom-pagination-s" // Use the custom pagination element
        }}
        modules={[Pagination]}
        onSlideChange={(swiper: SwiperCore) => setActiveIndex(swiper.realIndex)} // Update active index
        className="flex w-full pb-10 "
      >
        <SwiperSlide>
          <img src={notime} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime} alt="Slide 3" />
        </SwiperSlide>
      </Swiper>

      {/* Custom Pagination */}
      <div className="custom-pagination-s mt-8 flex justify-center space-x-3">
        {[0, 1, 2].map((index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-3 h-3 rounded-full ${
              activeIndex === index ? "bg-[#010a4f]" : "bg-gray-300"
            }`}
          />
        ))}
      </div>

      <Link
        to="/shop"
        className="mt-16 mb-10 font-berthold tracking-wide w-fit px-5 py-2 text-3xl font-bold rounded-lg uppercase bg-[#010a4f] text-white"
      >
        Shop now
      </Link>
    </div>
  );
}

export default PageRecipies;
