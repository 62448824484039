import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import pageBottom2 from "images/pagebottom2.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RegisterUser, signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from "lodash";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { getSignUpVerification } from "Services/API/Get";
import { addToast } from "shared/Toast";
import LoadingSpinner from "shared/LoadingSpinner";
import newbackground from "images/newbackground.png";
import register from "images/register.png";
import pagetop from "images/page-top.png";


import pageBottom from "images/pagebottom.png";

export interface PageSRegisterFirstProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageRegisterFirst: FC<PageSRegisterFirstProps> = ({ className = "" }) => {
  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errUniqueId, seterrUniqueId] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  React.useEffect(() => {
    getVerification();
  }, []);

  const getVerification = async (e?: any) => {
    let token = query.get("token");
    if (token !== null) {
      setaKey("register");
      setlopen(true);
      // setProgress(true);
      getSignUpVerification(token)
        .then((res) => {
          RegisterUser(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast(`Registration Successful `, "success");
              history.push("/login");
              setaKey("login");
              // setuserId(res.data[0]);
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Register Successful");
              // setTimeout(() => {
              //   setlopen(false);
              //   //  setOpen(true);
              //   history.push("/login-register");
              //   // window.location.reload();
              //   // setProgress(false);
              // }, 2000);

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              setlopen(false);
              addToast(error.response.data.message, "warning");

              // console.log("Error", error.response);
              // setProgress(false);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setProgress(false);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const sendVerification = async (e: any) => {
    e.preventDefault();

    if (uniqueId === "") {
      seterrUniqueId(true);
    }

    if (password === "") {
      seterrPass(true);
    }

    let obj = {
      data: {
        unique_id: uniqueId,
        password: password
      }
    };

    if (uniqueId !== "" && password !== "") {
      setlopen(true);
      signUpVerification(obj)
        .then((res) => {
          setlopen(false);
          addToast(
            `Please Login and complete the profile. You will be redirected to the login page now `,
            "success"
          );
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
        });
    } else {
      // scrollElement();
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Register || Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/register/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/signup"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className=" mb-24 lg:mb-16 font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={register} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="max-w-lg mx-auto space-y-6 pt-16 ">
          <form
            className="grid grid-cols-1 gap-8 bg-[#f0f0f0] rounded-lg p-10"
            onSubmit={sendVerification}
          >
            <label className="block">
              <span className="text-[#010a4f] text-[22px] font-semibold dark:text-neutral-200">
                Unique ID:
              </span>
              <Input
                type="text"
                placeholder="Unique ID"
                className="mt-1 font-GlacialIndifference font-semibold text-base border-neutral-800"
                onChange={(e) => {
                  setUniqueId(e.target.value);
                  seterrUniqueId(false);
                }}
                value={uniqueId}
              />
              {errUniqueId && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Required
                </div>
              )}
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-[#010a4f] text-[22px] ">
                Create Password:
              </span>
              <Input
                type="password"
                className="mt-1 font-GlacialIndifference font-semibold border-neutral-800"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  seterrPass(false);
                }}
                value={password}
              />
              {errPass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Requied
                </div>
              )}
            </label>
            <button
              type="submit"
              className="disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl py-2 text-lg font-GlacialIndifference font-semibold"
            >
              Create Profile
            </button>

            <span className="block font-GlacialIndifference text-center text-sm text-neutral-700 dark:text-neutral-300">
              Already have an account? {` `}
              <Link className=" font-semibold underline" to="/login">
                Sign in
              </Link>
            </span>
          </form>

          {/* ==== */}
        </div>
      </div>
      <div className="w-full flex  flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default PageRegisterFirst;
