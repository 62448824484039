import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import PageNewHome from "containers/PageNewHome/PageNewHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageShopSuccess from "containers/PageShopSuccess/PageShopSuccess";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import CartCount from "Constant/CartCount";
import AuthContext from "Context/AuthContext";
import CartContext from "Context/CartContext";
import StepsContext from "Context/StepsContext";
import { CurrentUser } from "Services/API/Get";
import LoadingSpinner from "shared/LoadingSpinner";
import ResetPass from "containers/Forgot Password";
import UpdatePass from "containers/Update Password";
import SwitchDarkMode2 from "shared/SwitchDarkMode/SwitchDarkMode2";
import TotalContext from "Context/TotalContext";
import UserWishList from "Constant/WishList";
import WishContext from "Context/WishContext";
import PageServicing from "containers/PageAbout/PageServicing";
import PageFinance from "containers/PageAbout/PageFinance";
import PageShipping from "containers/PageAbout/PageShipping";
import PageReturns from "containers/PageAbout/PageReturns";
import PageConditions from "containers/PageAbout/PageConditions";
import BlogSingle2 from "containers/BlogPage/BlogSingle2";
import BlogSingle3 from "containers/BlogPage/BlogSingle3";
import PageScrubber from "containers/PageAbout/PageScrubber";
import PageSweeper from "containers/PageAbout/PageSweeper";
import PageVaccum from "containers/PageAbout/PageVaccum";
import PageCarpet from "containers/PageAbout/PageCarpet";
import PageRegisterFirst from "containers/PageRegisterFirst/PageRegisterFirst";
import PageAboutUs from "containers/PageAboutUs/PageAboutUs";
import PageNewContact from "containers/PageNewContact/PageNewContact";
import PageRecipies from "containers/PageRecipies/PageRecipies";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageNewHome },
  // { path: "/#", exact: true, component: PageHome },
  // { path: "/home2", exact: true, component: PageHome2 },
  // { path: "/home3", exact: true, component: PageHome3 },
  //
  // { path: "/home-header-2", exact: true, component: PageHome },
  { path: "/product-detail/:id", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/shop", component: PageCollection2 },
  { path: "/recipies", component: PageRecipies },
  { path: "/about", component: PageAboutUs },
  // { path: "/page-collection", component: PageCollection },
  // { path: "/page-search", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageNewContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/register", component: PageRegisterFirst },
  { path: "/login", component: PageLogin },
  { path: "/shop-success", component: PageShopSuccess },
  // { path: "/subscription", component: PageSubcription },
  { path: "/reset-password", component: ResetPass },
  { path: "/update-password", component: UpdatePass },
  { path: "/servicing", component: PageServicing },
  { path: "/finance", component: PageFinance },
  { path: "/shipping", component: PageShipping },
  { path: "/returns", component: PageReturns },
  { path: "/conditions", component: PageConditions },
  { path: "/blog-single-2", component: BlogSingle2 },
  { path: "/blog-single-3", component: BlogSingle3 },
  { path: "/service-scrubber", component: PageScrubber },
  { path: "/service-sweeper", component: PageSweeper },
  { path: "/service-vaccum", component: PageVaccum },
  { path: "/service-carpet", component: PageCarpet }
];

const Routes = () => {
  const [user, setUser] = useState(null);
  const [lopen, setlopen] = useState(false);

  const [cart, setCart] = useState(0);
  const [wish, setWish] = useState<any>([]);
  const [cartotal, setcartotal] = useState(0);
  const [steps, setSteps] = useState(1);
  const [menuCategory, setMenuCategory] = useState(null);
  const testRef = useRef(null);
  const testRef2 = useRef(null);
  const testRef3 = useRef(null);

  useEffect(() => {
    const response = window.localStorage.getItem(
      `${window.location.hostname}.login`
    );
    if (response) {
      CartCount().then((res) => {
        console.log("count res-------->", res);
        setCart(res);
        if (res !== 0) {
          setSteps(4);
        }
        if (window.localStorage.getItem(`order`) === "true") {
          setSteps(6);
        }
      });

      UserWishList().then((res) => {
        console.log("count res-------->", res);
        setWish(res);
      });

      console.log("-------->", response);

      setSteps(2);
      setlopen(true);
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];
            console.log("+++++++++++", cData);
            const response = window.localStorage.setItem(
              `${window.location.hostname}.userDetails`,
              JSON.stringify(cData)
            );
            setUser(cData);
            setTimeout(() => {
              setlopen(false);
            }, 3000);

            if (cData.packageId !== null) {
              setSteps(3);
            }
          } else {
            setlopen(false);
          }
          if (window.localStorage.getItem(`order`) === "true") {
            setSteps(6);
          }
        })
        .catch((err) => {
          setlopen(false);

          console.log("userErr>>>>>>>>>", err);
        });
    } else {
      if (!window.localStorage.getItem("tempUser")) {
        console.log(
          ">>>>>>>QQQCCC",
          Math.floor(100000 + Math.random() * 900000)
        );

        window.localStorage.setItem(
          "tempUser",
          Math.floor(100000 + Math.random() * 900000).toString()
        );

        window.location.reload();
      }

      CartCount().then((res) => {
        console.log("count res-------->", res);
        setCart(res);
        if (res !== 0) {
          setSteps(4);
        }
        if (window.localStorage.getItem(`order`) === "true") {
          setSteps(6);
        }
      });

      UserWishList().then((res) => {
        console.log("count res-------->", res);
        setWish(res);
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user: user, setUser: setUser }}>
      <StepsContext.Provider value={{ steps: steps, setSteps: setSteps }}>
        <WishContext.Provider value={{ wish: wish, setWish: setWish }}>
          <TotalContext.Provider
            value={{ cartotal: cartotal, setcartotal: setcartotal }}
          >
            <CartContext.Provider
              value={{
                cart: cart,
                setCart: setCart
              }}
            >
              <LoadingSpinner open={lopen} />
              <div style={{ display: "none" }}>
                <SwitchDarkMode2 />
              </div>
              <Router basename="/">
                <ScrollToTop />
                <SiteHeader />
                <Switch>
                  {pages.map(({ component, path, exact }) => {
                    return (
                      <Route
                        key={path}
                        component={component}
                        exact={!!exact}
                        path={path}
                      />
                    );
                  })}
                  <Route component={Page404} />
                </Switch>
                <Footer />
              </Router>
            </CartContext.Provider>
          </TotalContext.Provider>
        </WishContext.Provider>
      </StepsContext.Provider>
    </AuthContext.Provider>
  );
};

export default Routes;
