import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getCompany } from "Services/API/Get";
import footerimg from "../../images/footer-img.png";
import facobook from "../../images/facebook.png";
import linkd from "../../images/linkd.png";
import insta from "../../images/insta.png";
import mail from "../../images/mail.png";
import phone from "../../images/phone.png";
import fphone from "../../images/fphone.png";
import location from "../../images/location.png";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Site Navigation",
    menus: [
      { href: "/servicing", label: "Repairs and Servicing" },
      { href: "/blog", label: "Cleaning Tips" },
      { href: "/about", label: "About" },
      { href: "/contact", label: "Contact" }
    ]
  },
  {
    id: "1",
    title: "Shipping & Terms",
    menus: [
      { href: "/shipping", label: "Shipping" },
      { href: "/returns", label: "Returns" },
      { href: "/finance", label: "Finance" },
      { href: "/conditions", label: "Terms & Conditions" }
    ]
  }
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "#", label: "Best practices" },
  //     { href: "#", label: "Support" },
  //     { href: "#", label: "Developers" },
  //     { href: "#", label: "Learn design" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "API Reference" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const [company, setcompany] = useState<any>("");
  const [lopen, setlopen] = useState<any>(false);

  const CompanyGet = () => {
    setlopen(true);
    getCompany()
      .then((res) => {
        setlopen(false);
        console.log(res.data);
        console.log("company res>>>", res.data[res.data.length - 1]);
        setcompany(res.data[res.data.length - 1]);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };

  React.useEffect(() => {
    CompanyGet();
  }, []);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-base lg:ml-[90px]">
        <h2 className="font-semibold text-white dark:text-white">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <NavLink
                to={{ pathname: item.href }}
                key={index}
                className="text-white dark:text-white hover:text-white dark:hover:text-white"
                // href={item.href}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    // <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 dark:border-neutral-700 bg-[#74BC1F]">
    //   <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-35 ">
    //     <div className="grid grid-cols-1 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
    //       <div className="col-span-1 md:col-span-1">
    //         <Logo type={"footer"}/>
    //       </div>
    //       <div className="col-span-1 flex items-center md:col-span-3">
    //         {/* <span >

    //           <text className="text-neutral-6000  dark:text-white text-xs whitespace-nowrap " >

    //           {company?.address},{" "}

    //           {company?.suburb} , {company?.postCode}  </text><br/>

    //           <a href={`mailto:${company?.email}`} className="text-neutral-6000 cursor-pointer dark:text-white text-xs whitespace-nowrap hover:text-black dark:hover:text-white " >
    //               {company?.email}
    //             </a> <br/>

    //             <a href={`Tel:${company?.phoneNumber}`} className="text-neutral-6000 cursor-pointer dark:text-white text-xs whitespace-nowrap hover:text-black dark:hover:text-white " >
    //               {company?.phoneNumber}
    //             </a>
    //         </span> */}

    //       </div>
    //       <div className="col-span-1 flex items-center md:col-span-3">

    //         <a href="https://ecomfixerr.com" target="_blank" rel="noopener noreferrer" className="text-white dark:text-white text-sm">
    //           © 2022 Powered by Ecomfixerr. <br />
    //           All Rights Reserved
    //         </a>
    //         {/* <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" /> */}
    //       </div>
    //     </div>

    //     {widgetMenus.map(renderWidgetMenuItem)}
    //     <div className="text-base lg:ml-[90px]">
    //       <h2 className="font-semibold text-white dark:text-white">
    //         Follow Us
    //       </h2>
    //       <ul className="mt-5 space-y-4">

    //         <li >
    //           <SocialsList1 href={company?.facebook} className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
    //         </li>

    //       </ul>
    //     </div>
    //   </div>
    // </div>

    <div className="w-full  relative ">
      <div className=" absolute left-[52%] top-10 flex flex-col justify-center  text-white">
        <div className=" font-berthold text-7xl">CONTACT US</div>
        <div className=" font-GlacialIndifference text-2xl mt-3">
          Drop us an email or give us a call!
        </div>

        <div className=" flex flex-col gap-3 font-GlacialIndifference mt-10">
          <div className="flex w-full gap-5 items-center text-white  font-GlacialIndifference text-xl">
            <span>
              <img src={mail} className="w-8" alt="" />
            </span>
            <span>marketing@silvermillgroup.com</span>
          </div>
          <div className="flex w-full gap-5 items-center text-white  font-GlacialIndifference text-xl">
          <span>
              <img src={phone} className="w-8" alt="" />
            </span>
            <span>(+94) 70 4767523</span>
          </div>
          <div className="flex w-full gap-5 items-center text-white  font-GlacialIndifference text-xl">
          <span>
              <img src={fphone} className="w-8" alt="" />
            </span>
            <span>(+94) 11 7389376 /77</span>
          </div>
          <div className="flex w-full gap-5 items-center text-white  font-GlacialIndifference text-xl">
          <span>
              <img src={mail} className="w-8" alt="" />
            </span>
            <span>No. 418/1 Kynsey Roaad, Colombo 08, Sri Lanka</span>
          </div>
        </div>

        <div className="flex gap-3 items-center mt-10">
          <span className=" font-berthold text-white text-3xl">
            GET SOCIAL WITH US
          </span>
          <span>
            <img src={facobook} className="w-9" alt="" />
          </span>
          <span>
            <img src={insta} className="w-9" alt="" />
          </span>
          <span>
            <img src={linkd} className="w-9" alt="" />
          </span>
        </div>
      </div>
      <img className="w-full   pt-10 bg-[#010a4f]" src={footerimg} alt="" />
    </div>
  );
};

export default Footer;
