import Label from "components/Label/Label";
import Accordion from "react-bootstrap/Accordion";
import React, { FC, useState, useEffect, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { avatarImgs } from "contains/fakeData";
import { EditUserQuery, UpdateUserwithemail } from "Services/API/Put";
import { signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from "lodash";
import { addToast } from "shared/Toast";
import { ChangeEvent } from "react";
import {
  CurrentUser,
  getDistricts,
  getSignUpVerification
} from "Services/API/Get";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import AuthContext from "Context/AuthContext";
import LoadingSpinner from "shared/LoadingSpinner";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import newbackground from "images/newbackground.png";
import { SERVER_URL, SERVER_URL_DEV } from "Constant/System";
import profile from "images/profile.png";
import pagetop from "images/page-top.png";
import Checkbox from 'shared/Checkbox/Checkbox';

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const { user, setUser } = useContext(AuthContext);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [lopen, setlopen] = React.useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [employee, setEmployee] = useState("");
  const [company, setCompany] = useState("");

  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [erremplyee, seterremployee] = useState(false);
  const [errcompany, seterrcompany] = useState(false);
  const [erruniqueid, seterruniqueid] = useState(false);

  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");

  const [userId, setuserId] = useState("");
  const [currUser, setcurrUser] = useState<any>("");
  const [eDisable, seteDisable] = useState(true);
  const [eDisable2, seteDisable2] = useState(true);

  const [image, setImage] = useState("");
  const [uimage, seutImage] = useState("");

  const onSubmit = async (e?: any) => {
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();
    console.log(firstName);
    if (firstName === "" || firstName === null) {
      seterrfirstName(true);
    }
    if (lastName === "" || lastName === null) {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }

    // if (homeNo === "") {
    //   seterrhomeNo(true);
    // }
    // if (mobile === "") {
    //   seterrmobile(true);
    // }
    // if (password !== "") {
    //   //   seterrPass(true)
    //   if (repassword === "") {
    //     seterrRePass("This Field is Requied");
    //   } else {
    //     seterrRePass("");
    //   }
    // }

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }

    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      image: image,
      firstName: upperFirst(lowerCase(firstName)),
      lastName: upperFirst(lowerCase(lastName)),
      mobileNumber: mobile,
      email: email,
      employee: employee,
      company_id: currUser?.company_id,
      registrationcomplete: currUser.registrationcomplete
    };

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      employee !== "" &&
      // (password !== "" ? repassword !== "" : firstName !== "") &&
      phoneRegex.test(mobile)
    ) {
      setlopen(true);
      EditUserQuery(obj)
        .then((res) => {
          setlopen(false);

          if (currUser.registrationcomplete === 0) {
            addToast(
              "Request was sent to Silvermill team to approve",
              "success"
            );
          } else {
            addToast("Update Successful", "success");
          }
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Update Successful");
          getCurrentUser();
          seteDisable(true);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      // scrollElement();
    }
  };

  const getVerification = () => {
    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setlopen(true);
      getSignUpVerification(token)
        .then((res) => {
          UpdateUserwithemail(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast("Update Successful", "success");
              getCurrentUser();
              history.push("/account");

              // setTimeout(() => {
              //   history.push("/account");
              //   window.location.reload();
              // }, 2000);
            })
            .catch((error) => {
              // setlopen(false);
              console.log("Error", error.response);
              setlopen(false);
              addToast(error.response.data.message, {
                appearance: "warning",
                autoDismiss: true
              });
            });
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setlopen(false);
          addToast(error.response.data.message, {
            appearance: "warning",
            autoDismiss: true
          });
        });
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          console.log("usee data account", cData);
          setUser(cData);
          const obj = {
            id: cData.id,
            unique_id: cData?.unique_id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
            status: cData?.status
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setpremail(cData?.email);
          setmobile(cData?.mobileNumber);
          setUniqueId(cData?.unique_id);
          setEmployee(cData?.employee);
          setuserId(cData?.id);
          setCompany(cData?.company);
          seutImage(cData?.image);
          getDistricts();

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        if (error.response && error.response.data) {
          console.log("current user error", error.response.data.message);
        }
        history.push("/login");
      });
  };

  const [isAccountOpen, setIsAccountOpen] = useState(true);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);

  const toggleAccount = () => setIsAccountOpen(!isAccountOpen);
  const togglePassword = () => setIsPasswordOpen(!isPasswordOpen);
  React.useEffect(() => {
    getCurrentUser();
    getVerification();
  }, []);

  const onChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const baseURL = reader.result;
        if (typeof baseURL === "string") {
          console.log("img>>>", baseURL);
          setImage(baseURL); // Assuming setImage is defined elsewhere
        }
      };
    }
  };
  console.log("cro", currUser);
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Silvermill </title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
//   {
//   "@context" : "http://schema.org",
//   "@type" : "LocalBusiness",
//   "name": "Oz Cleaning Supplies",
//   "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
//   "telephone": "+61 0397940654",
//   "email": "info@ozcleaningsupplies.com.au",
//   "address": {
//     "@type": "PostalAddress",
//     "streetAddress" : "167 Cheltenham Road",
//     "addressLocality": "Dandenong",
//     "addressRegion" : "VIC ",
//     "addressCountry": "Australia",
//     "postalCode" : "3175"
//   },
//   "url": "https://www.ozcleaningsupplies.com.au${path}"
// }
    `}</script>
      </Helmet>
      {/* <LoadingSpinner open={lopen} /> */}
      <div className=" mb-24 lg:mb-32 font-berthold ">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={profile} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        {currUser?.status === 0 && (
          <div className="max-w-4xl mx-auto flex justify-center text-center mt-6 py-5 bg-green-300 rounded-lg ">
            Your account is in review
          </div>
        )}
        {currUser?.status === 2 && (
          <div className="max-w-4xl mx-auto flex justify-center text-center mt-6 py-5 bg-red-300 rounded-lg ">
            Your request is rejected please contact employee
          </div>
        )}

        <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
          <div className="flex flex-col bg-[#f0f0f0] rounded-lg p-10">
            <div
              className="w-full flex justify-between pb-6 p-5 px-10 border-b-[1px] border-white"
              onClick={toggleAccount}
            >
              <span className="text-[28px] text-[#010a4f]">
                1. Account infomation
              </span>
              <span>
                <ChevronDownIcon
                  className="ml-1 -mr-1 h-6 w-6 text-slate-700"
                  aria-hidden="true"
                />
              </span>
            </div>

            <div
              className={`w-full flex flex-wrap justify-between  px-10 transition-max-height gap-6 duration-500 ease-in-out overflow-hidden ${
                isAccountOpen ? "max-h-[1000px] p-5" : "max-h-0"
              }`}
            >
              <div className=" md:w-[48%] w-full flex md:hidden justify-end items-center w-full">
                <div className=" text-sm font-semibold mr-2">
                  Upload your photo
                </div>
                <div className="relative rounded-full overflow-hidden flex">
                  {image !== "" ? (
                    <img
                      src={image}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  ) : uimage !== "" ? (
                    <img
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                      src={`${SERVER_URL_DEV}${uimage}`}
                    />
                  ) : (
                    <img
                      src={avatarImgs[2]}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  )}

                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {/* <span className="mt-1 text-[10px]">Change Image</span> */}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onChangeAvatar}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide   ">
                  Unique ID
                </Label>
                <Input
                   className="mt-1.5 font-GlacialIndifference text-base"
                  disabled
                  placeholder="ACB-XXXX"
                  onChange={(e) => {
                    setUniqueId(e.target.value);
                    seterruniqueid(false);
                  }}
                  value={uniqueId}
                />
                {erruniqueid && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              {/* <div className=" md:w-[48%] w-full hidden md:flex justify-end items-center w-full">
                <div className=" text-sm font-semibold mr-2">
                  Upload your photo
                </div>
                <div className="relative rounded-full overflow-hidden flex">
                  {image !== "" ? (
                    <img
                      src={image}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  ) : uimage !== "" ? (
                    <img
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                      src={`${SERVER_URL}${uimage}`}
                    />
                  ) : (
                    <img
                      src={avatarImgs[2]}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  )}

                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                        </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onChangeAvatar}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div> */}

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  First Name
                </Label>
                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  placeholder="First Name"
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    seterrfirstName(false);
                  }}
                  value={firstName}
                />
                {errfirstName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Last Name
                </Label>
                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setlasttName(e.target.value);
                    seterrlasttName(false);
                  }}
                  value={lastName}
                />
                {errlastName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Email
                </Label>

                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  placeholder="example@email.com"
                  onChange={(e) => {
                    setemail(e.target.value);
                    seterremail(false);
                  }}
                  value={email}
                />

                {erremail && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>
              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Company
                </Label>
                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  placeholder="Company"
                  disabled
                  onChange={(e) => {
                    setCompany(e.target.value);
                    seterrcompany(false);
                  }}
                  value={company}
                />
                {errcompany && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>
              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Employee Code
                </Label>
                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  placeholder="Employee Code"
                  onChange={(e) => {
                    setEmployee(e.target.value);
                    seterremployee(false);
                  }}
                  value={employee}
                />
                {errlastName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Contact number
                </Label>

                <Input
                    className="mt-1.5 font-GlacialIndifference text-base"
                  // defaultValue="003 888 232"
                  placeholder="Enter Mobile"
                  onChange={(e) => {
                    setmobile(e.target.value);
                    seterrmobile("");
                  }}
                  value={mobile}
                />

                {errmobile && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    {errmobile}
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  &nbsp;
                </Label>
                <ButtonPrimary className="w-full mt-1.5 font-GlacialIndifference font-semibold" onClick={onSubmit}>
                  Update Account
                </ButtonPrimary>
              </div>

              <div className=" flex w-full flex-col font-GlacialIndifference gap-3 text-[#010a4f] text-base mt-3 ">
              <p>
                The Silvermill Corporate Welfare Program is an exclusive offer
                provided to our Corporate Partners who have joined us to provide
                the Program benefits to their employees. All products ordered
                will be delivered to the venue of our Corporate Partners. ​
              </p>
              <p className="mb-5">
                Once you register, we will contact your employer to confirm that
                you are an employee of our Corporate Partners. Once this is
                confirmed, your account will be activated and you will be able
                to order our discounted products!
              </p>
              <Checkbox
          name={""}
          label={" By registering, you understand and consent to the above. "}
          // checked={selectedCat === ""}
          sizeClassName="w-5 h-5"
          labelClassName="font-GlacialIndifference text-[#010a4f] text-base font-normal"
          onChange={(checked) => {
           
            // window.scrollTo({
            //   top: 0,
            //   behavior: "smooth"
            // });
          }}
          // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
        />
            </div>
            </div>
         
          </div>

          {/* <div
            className="flex flex-col bg-[#f1f1f0] mt-8  rounded-lg"
            onClick={togglePassword}
          >
            <div className="w-full flex justify-between  pb-6 p-5 px-10 border-b-[1px] border-white">
              <span className="text-base">2. Change Password</span>
              <span>
                <ChevronDownIcon
                  className="ml-1 -mr-1 h-6 w-6 text-slate-700"
                  aria-hidden="true"
                />
              </span>
            </div>
            {isPasswordOpen && (
              <div className="w-full flex flex-col p-5 px-10  mt-10 md:mt-0">
                <div className="w-full flex justify-between   ">
                  <div className="md:w-[48%] w-full">
                    <Label className="text-[#545454]">Current Password</Label>
                    <Input
                        className="mt-1.5 font-GlacialIndifference text-base"
                      placeholder="ACB-XXXX"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                        seterrfirstName(false);
                      }}
                      value={firstName}
                    />
                    {errfirstName && (
                      <div className="text-red-600 text-xs text-right mt-2">
                        This Field is Requied
                      </div>
                    )}
                  </div>
                  <div className="md:w-[48%] w-full">
                    <Label className="text-[#545454]">New Password</Label>
                    <Input
                        className="mt-1.5 font-GlacialIndifference text-base"
                      placeholder="ACB-XXXX"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                        seterrfirstName(false);
                      }}
                      value={firstName}
                    />
                    {errfirstName && (
                      <div className="text-red-600 text-xs text-right mt-2">
                        This Field is Requied
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full flex justify-end">
                  <div className="pt-2 pb-5 md:w-[48%] w-full mt-5">
                    <ButtonPrimary className="w-full" onClick={onSubmit}>
                      Update Password
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
