import React, { FC, useContext, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import logoImg from "images/logo-big.png";
import cartImg from "images/cart.png";
import AuthContext from "Context/AuthContext";

export interface MainNav2LoggedProps {}

const MainNavNew: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);

  const { user, setUser } = useContext(AuthContext);

  const [userD, setUserD] = React.useState<any | null>("");

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  React.useEffect(() => {
    let userNew: any = localStorage.getItem(
      `${window.location.hostname}.userDetails`
    );

    userNew = JSON.parse(userNew);

    // console.log("--------->", user);
    // const response = window.localStorage.getItem(
    //   `${window.location.hostname}.userDetails`
    // );
    // setUserD(JSON.parse(response));
    let obj: any = {
      unique_id: user?.unique_id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    };

    if (userNew) {
      setUserD(obj);
    } else {
      setUserD("none");
    }
  }, [user]);
  console.log(userD);
  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        action=""
        method="POST"
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm"
            autoFocus
          />
          <button onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className=" flex justify-between py-3 font-berthold tracking-wider">
       <Link
          to="/"
          className="flex md:hidden items-center justify-center lg:w-[35%]"
        >
          <img src={logoImg} className=" w-[300px]" alt="" />
        </Link>
        <div className="flex items-center lg:hidden">
          <MenuBar userD={userD} />
        </div>
        <div className=" hidden lg:flex justify-start py-5 gap-5 md:justify-between items-center lg:w-[25%]">
          <Link
            to="/shop"
            className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
          >
            Shop
          </Link>

          <Link
            to="/about"
            className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
          >
            About
          </Link>
          <Link
            to="/recipies"
            className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
          >
            Recpies
          </Link>
        </div>
        <Link
          to="/"
          className="lg:flex md:flex items-center hidden  justify-center lg:w-[45%]"
        >
          <img src={logoImg} className=" w-[300px]" alt="" />
        </Link>

        <div className=" hidden lg:flex justify-center py-5 gap-5 items-center md:justify-between mx-4 lg:w-[32%]">
          <Link
            to="/contact"
            className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
          >
            Contact
          </Link>
          {userD !== "" && userD === "none" ? (
            <Link
              to="/register"
              className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
            >
              Register
            </Link>
          ) : (
            <Link
              to="/account"
              className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
            >
              Account
            </Link>
          )}

          {userD !== "" && userD === "none" ? (
            <Link
              to="/login"
              className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
            >
              Log-in
            </Link>
          ) : (
            <button
              onClick={() => {
                console.log("logout>>>>");
                window.localStorage.removeItem(
                  `${window.location.hostname}.login`
                );
                window.localStorage.removeItem("exprTime");
                window.localStorage.clear();
                // window.location.reload();

                window.location.href = "/";
              }}
              className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase  underline-offset-8 hover:underline"
            >
              Log-out
            </button>
          )}

          <Link to="/cart" className="font-semibold text-[28px] 2xl:text-3xl text-black uppercase">
            <img src={cartImg} className="w-10 h-10" alt="" />
          </Link>
        </div>
        {/* <div className=" flex items-center gap-3 justify-end text-slate-700 dark:text-slate-100">
       
          <Link
            to="/register"
            className=" flex px-14 py-2 text-xs rounded-md text-white font-semibold  bg-[#cd294b] "
          >
            Register
          </Link>
          {userD !== "" && userD === "none" ? (
            <Link
              to="/login"
              className=" flex px-14 py-2 text-xs rounded-md  text-white font-semibold bg-[#21264b] "
            >
              Login
            </Link>
          ) : (
            <button
              onClick={() => {
                console.log("logout>>>>");
                window.localStorage.removeItem(
                  `${window.location.hostname}.login`
                );
                window.localStorage.removeItem("exprTime");
                window.localStorage.clear();
                history.push("/");
                window.location.reload();
              }}
              className=" flex px-14 py-2 text-xs rounded-md  text-white font-semibold bg-[#21264b] "
            >
              Logout
            </button>
          )}

      
        </div> */}
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="px-[5%] ">{renderContent()}</div>
    </div>
  );
};

export default MainNavNew;
