import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import newbackground from "images/newbackground.png";
import Button from "shared/Button/Button";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageShopSuccess: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  let userNew: any = localStorage.getItem(
    `${window.location.hostname}.userDetails`
  );
  userNew = JSON.parse(userNew);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Success</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/login/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/login"
}
    `}</script>
      </Helmet>
      <div className=" mb-24 lg:mb-32 font-berthold">
        <div className="w-full relative flex flex-col h-[50vh] gap-5 justify-center font-berthold items-center bg-[#ffe1e7]">
          <div className=" absolute top-0 left-0 w-full h-[50vh] ">
            <img className=" w-full h-[50vh]" src={newbackground} alt="" />
          </div>
          <h2 className="z-[100] flex items-center font-berthold text-[#ffe680] text-2xl leading-[115%] md:text-5xl uppercase tracking-wider md:leading-[115%] font-bold justify-center">
            Success
          </h2>
          <div className="text-base z-[100]  font-berthold text-center tracking-wider text-white md:w-[45%]">
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, ween the industry's standard dummy text ever since the
          </div>
        </div>
        <div className="max-w-md mx-auto space-y-6 pt-16">
          <div className=" font-light">
            Welcome ( {userNew.firstName} )! We are happy to be serving you
            through the Silvermill Corporate Welfare Program in partnership with
            ( {userNew.company} )!
          </div>

          <div className=" bg-[#e2fff1] p-5 flex flex-col rounded-lg mt-5 gap-1 ">
            <div className=" text-sm tracking-wider font-semibold text-[#00bf63] ">
              Delivery Date
            </div>
            <div className=" text-xs text-[#00bf63] ">
              Please note that your orders will be delivered to ({" "}
              {userNew.company} ). If you are no longer working for ({" "}
              {userNew.company} ) please contact us immediately
            </div>
          </div>
          <Link
          to="/shop"
            className={`ttnc-ButtonPrimary flex justify-center  py-3 px-4 sm:py-3.5 sm:px-6 w-full disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl ${className}`}
          >
            Continue Shopping
          </Link>
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-xs font-medium text-neutral-700 dark:text-neutral-300 sm:text-xs">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xs bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}

          {/* ==== */}
        </div>
      </div>
    </div>
  );
};

export default PageShopSuccess;
