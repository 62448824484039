import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumberNew";
import Prices from "components/Prices";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import CartContext from "Context/CartContext";
import StepsContext from "Context/StepsContext";
import TotalContext from "Context/TotalContext";
import { Product, PRODUCTS } from "data/data";
import jwtDecode from "jwt-decode";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DeleteCard } from "Services/API/Delete";
import {
  CurrentUser,
  getCartList,
  getDeliveryDateByCart
} from "Services/API/Get";
import { UpdateCartItems } from "Services/API/Put";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LoadingSpinner from "shared/LoadingSpinner";
import newbackground from "images/newbackground.png";
import carthero from "images/carthero.png";

import pagetop from "images/page-top.png";

import pageBottom from "images/pagebottom.png";

const CartPage = () => {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);
  const [deliveryDate, setdeliveryDate] = useState("");
  const [currUser, setcurrUser] = useState<any>("");

  const [array, setArray] = useState([]);
  const [Total, setTotal] = useState<any>(0);
  const [TotalMrpValue, setTotalMrpValue] = useState<any>(0);
  const [TotalSaving, setTotalSaving] = useState<any>(0);

  const [lopen, setlopen] = useState(true);

  React.useEffect(() => {
    getCart();
  }, [cart]);

  const getCart = () => {
    setlopen(true);
    getCartList()
      .then((res) => {
        // let cartTotalPrice = 0;
        // const abc = res.data?.map((e: any) => {
        //   let price = e.specialprice ? e.specialprice : e.mrp;
        //   const finalProductPrice = parseFloat(price).toFixed(2);

        //   cartTotalPrice += parseFloat(finalProductPrice) * e.qty;
        //   // m = m + e.qty * e.discountedprice;
        // });
        setArray(res.data?.cart_items);
        setTotal(res.data?.total);
        setcartotal(res.data?.total);
        setTotalMrpValue(res.data?.total_mrp_value);
        setTotalSaving(res.data?.total_saving);
        setlopen(false);
        CartCount().then((res) => {
          setCart(res);
        });
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        setArray([]);
        setTotal(0);
        setcartotal(0);
        setlopen(false);
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });

          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const getDeliveryDate = () => {
    getDeliveryDateByCart(currUser?.company_id)
      .then((res) => {
        setdeliveryDate(res.data?.nextDeliveryDate);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setdeliveryDate("");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  React.useEffect(() => {
    getDeliveryDate();
  }, [currUser]);

  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-[10px] text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-[10px] text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">In Stock</span>
      </div>
    );
  };

  const renderProduct = (item: any, index: number) => {
    const { image, price, name } = item;
    let value = item?.qty;

    return (
      <div
        key={index}
        className="relative flex pb-3 sm:pt-10 xl:pt-12 first:pt-0 border-b-2 border-[#767178] "
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={image}
            src={`${IMAGE_SERVER_URL_DEV}${
              JSON.parse(item?.imgArray)[0]?.path
            }`}
            alt={item?.productName}
            className="h-full w-full object-contain object-center"
          />
          <Link
            to={`/product-detail/${item?.productId}`}
            className="absolute inset-0"
          ></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <div className="flex flex-col">
                  <h3 className="text-xl text-[#010a4f] font-semibold font-GlacialIndifference">
                    <Link to={`/product-detail/${item?.productId}`}>
                      {" "}
                      {item?.productName}
                    </Link>
                  </h3>
                  <div className="flex items-center font-GlacialIndifference gap-2 text-sm mt-3">
                    <span
                      className={`${
                        item?.specialprice
                          ? "text-[#8a8888] line-through"
                          : "text-black"
                      }`}
                    >
                      MRP :{" "}
                      {Number(item?.mrp).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                    {item?.specialprice && (
                      <span>
                        Special Price :{" "}
                        {Number(item?.specialprice).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    )}
                  </div>

                  <div className="sm:block text-center mt-5 relative">
                    <NcInputNumber
                      className="relative z-10"
                      max={item?.availableStocks}
                      defaultValue={item?.qty}
                      qty={item}
                      getCart={getCart}
                    />
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <span className=" font-GlacialIndifference ">
                    LKR{" "}
                    {Number(item?.mrp).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <span className=" text-[#010a4f] font-GlacialIndifference ">
                  LKR {(item?.total_price).toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-2 items-end justify-between text-xs">
            {item?.availableStocks === 0
              ? renderStatusSoldout()
              : renderStatusInstock()}

            <a
              // href="##"
              className="relative z-10 flex items-center mt-2 font-normal text-red-500 hover:text-red-600 text-lg font-GlacialIndifference cursor-pointer"
            >
              <span
                onClick={() => {
                  setlopen(true);
                  DeleteCard(item?.cartId)
                    .then((res) => {
                      CartCount().then((res) => {
                        setCart(res);
                      });
                      // CartCount().then((res) => {
                      //   console.log("count res-------->", res);
                      //   setCart(res);
                      // });
                      // addToast(`${single.productName} Removed`, { appearance: "error", autoDismiss: true });
                      getCart();

                      //  this.setState(
                      //    {
                      //      open_toast: true,
                      //      alertClass: "error",
                      //      msg: `${data.displayName} Removed`,
                      //      color: "#635bff",
                      //    },
                      //    () => this.getAllCart()
                      //  );
                    })
                    .catch((error) => {
                      setlopen(false);
                      console.error("delete cart item error", error);
                    });
                }}
              >
                Remove
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Shopping Cart ||Silvermill</title>

        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      <div className="font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={carthero} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="container space-y-6 py-10 md:py-16 lg:space-y-6">
          {array.length > 0 ? (
            <>
              <div className="flex flex-col lg:flex-row lg:justify-between">
                <div className="w-full lg:w-[40%] xl:w-[40%]  ">
                  {array.map((item, index) => renderProduct(item, index))}
                </div>
                {/* <div className=" border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div> */}
                <div className=" lg:w-[40%]">
                  <div className="sticky top-28">
                    <h3 className="text-lg font-GlacialIndifference font-semibold ">
                      Order Summary
                    </h3>
                    <div className="mt-7 text-sm text-slate-500 ">
                      {array.map((item: any, index: any) => {
                        // Calculate the quantity the user needs to pay for based on the "Buy One Get One Free" rule
                        const paidQty = item?.buyone
                          ? Math.ceil(item.qty / 2)
                          : item.qty;

                        return (
                          <div
                            key={index}
                            className="flex font-GlacialIndifference text-[#9f99a1] flex-col"
                          >
                            {/* Main Product Row */}
                            <div className="flex justify-between pb-1 pt-2">
                              <div className="w-8/12 flex items-center gap-1">
                                <span>{item?.productName}</span> (
                                <span
                                  className={`${
                                    item?.specialprice ? "line-through" : ""
                                  }`}
                                >
                                  {parseFloat(item.mrp).toFixed(2)}
                                </span>
                                {item?.specialprice && (
                                  <span>
                                    {parseFloat(item.specialprice).toFixed(2)}
                                  </span>
                                )}
                                <span>) x {paidQty}</span>
                              </div>
                              <span className="font-normal  dark:text-slate-200 whitespace-nowrap">
                                LKR {(item?.total_price).toFixed(2)}
                              </span>
                            </div>

                            {/* Free Product Row if Buy One Get One */}
                            {item?.buyone === 1 && item.qty > 1 && (
                              <div className="flex justify-between pb-1 pt-2">
                                <div className="w-7/12 flex items-center gap-1">
                                  <span>{item?.productName} (Free)</span> x{" "}
                                  {Math.floor(item.qty / 2)}
                                </div>
                                <span className="font-normal  dark:text-slate-200 whitespace-nowrap">
                                  LKR 0.00
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-7 text-xs text-slate-500 font-GlacialIndifference ">
                      {/* <div className="flex justify-between pb-4">
                        <span>Subtotal</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${Total.toFixed(2)}`}
                        </span>
                      </div> */}
                      {/* <div className="flex justify-between pb-4">
                        <span>Shipping</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${16.95}`}
                        </span>
                      </div>
                      <div className="flex justify-between pb-4">
                        <span>Tax (10% GST)</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${((Total + 16.95) * 10 / 100).toFixed(2)}`}
                        </span>
                      </div> */}
                      {/* <div className="flex justify-between py-4">
                  <span>Shpping estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $5.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Tax estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $24.90
                  </span>
                </div> */}
                      <div className="flex justify-between font-bold text-[#545454] dark:text-slate-200 text-sm pt-2">
                        <span>Sub Total</span>
                        {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                        <span>{`LKR ${Total.toFixed(2)}`}</span>
                      </div>
                      <div className="flex justify-between font-bold text-[#767178] dark:text-slate-200 text-base pt-2">
                        <span>Grand Total</span>
                        {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                        <span>{`LKR ${Total.toFixed(2)}`}</span>
                      </div>
                      <div className="flex justify-between font-semibold text-[#767178] italic dark:text-slate-200 text-sm pt-2">
                        <span>Total Mrp Value</span>
                        {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                        <span>{`LKR ${TotalMrpValue.toFixed(2)}`}</span>
                      </div>
                      <div className="flex justify-between font-bold  text-[#02871a] italic dark:text-slate-200 text-sm pt-2">
                        <span>Total Saving</span>
                        {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                        <span>{`LKR ${TotalSaving.toFixed(2)}`}</span>
                      </div>
                    </div>

                    <div className=" bg-[#d9d9d9] font-GlacialIndifference p-5 flex flex-col rounded-lg mt-5 gap-1 ">
                      <div className=" text-sm font-semibold tracking-wider text-[#545454] ">
                        Delivery Date
                      </div>
                      <div className=" text-xs text-[#545454] ">
                        Your order is expected to be delivered on {deliveryDate}
                        . However, you will receive an SMS notification
                        confirming the delivery date.
                      </div>
                    </div>
                    <div className=" w-full flex justify-center items-center">
                      <Link
                        className="mt-8 w-fit px-20 py-3 ttnc-ButtonPrimary font-GlacialIndifference uppercase font-bold disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl "
                        to="/checkout"
                      >
                        Checkout
                      </Link>
                    </div>

                    {/* <div className="mt-5 text-xs text-slate-500 dark:text-slate-400 flex items-center justify-center">
                      <p className="block relative pl-5">
                        <svg
                          className="w-4 h-4 absolute -left-1 top-0.5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V13"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.9945 16H12.0035"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Learn more{` `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="##"
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Taxes
                        </a>
                        <span>
                          {` `}and{` `}
                        </span>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="##"
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Shipping
                        </a>
                        {` `} infomation
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No items added to cart
                </p>
              </div>
              <div className="flex mt-6 justify-center items-center">
                <a href="/shop">
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-full flex  flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default CartPage;
