import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import NcInputNumberNew from "components/NcInputNumberNew";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import CartContext from "Context/CartContext";
import TotalContext from "Context/TotalContext";
import {
  CurrentUser,
  getAllCities,
  getCartList,
  getDistricts,
  getCities,
  getProductById,
  getOrderDetails,
  getProductById2,
  getDeliveryDateByCart
} from "Services/API/Get";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { DeleteCard } from "Services/API/Delete";
import { Autocomplete } from "@mui/material";
import { OrderSave, RegisterUser } from "Services/API/Post";
import { EMAIL_TEMPLATE } from "./email";
import { EMAIL_TAMPLETE2 } from "./email2";
import { upperFirst, lowerCase } from "lodash";
import { EditUserQuery } from "Services/API/Put";
import moment from "moment";
import { addToast } from "shared/Toast";
import _ from "lodash";
import LoadingSpinner from "shared/LoadingSpinner";
import SubData from "./australian_postcodes.json";
import Textarea from "shared/Textarea/Textarea";
import newbackground from "images/newbackground.png";
import checkouthero from "images/checkouthero.png";

import pagetop from "images/page-top.png";

import pageBottom from "images/pagebottom.png";

const CheckoutPage = () => {
  const location = useLocation();
  const path = location.pathname;

  const [newSubArray, setnewSubArray] = useState<any>([]);

  const [newSubs, setnewSubs] = useState<any>([]);

  const [newSubs2, setnewSubs2] = useState([]);

  const history = useHistory();

  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);

  const [array, setArray] = useState<any>([]);
  const [Total, setTotal] = useState<any>(0);
  const [SubTotal, setSubTotal] = useState<any>(0);
  const [Total_Mrp_Value, setTotal_Mrp_Value] = useState<any>(0);
  const [Total_Saving, setTotal_Saving] = useState<any>(0);
  const [lopen, setlopen] = useState(true);

  const [cartproducts, setcartproducts] = useState<any>([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [country, setcountry] = useState("Australia");
  const [unitno, setunitno] = useState("");
  const [streetname, setstreetname] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");
  const [userId, setuserId] = useState("");
  const [notes, setnotes] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [currUser, setcurrUser] = useState<any>("");

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [errunitno, seterrunitno] = useState(false);
  const [errstreetname, seterrstreetname] = useState(false);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [tempUser, settempUser] = useState<any>(null);

  const [delType, setdelType] = useState("delivery");

  const [payDisable, setpayDisable] = useState(true);

  const [stDisable, setstDisable] = useState(true);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d: any) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));
  }, []);

  React.useEffect(() => {
    getCart();
  }, [cart]);
  React.useEffect(() => {
    getDeliveryDate();
  }, [currUser]);

  React.useEffect(() => {
    // getCurrentUser();
    listDistricts();
    listHomecities();
  }, []);

  React.useEffect(() => {
    getCurrentUser();
  }, [newSubArray]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const getDeliveryDate = () => {
    getDeliveryDateByCart(currUser?.company_id)
      .then((res) => {
        setdeliveryDate(res.data?.nextDeliveryDate);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setdeliveryDate("");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listHomecities2 = () => {
    getCities("VIC")
      .then((res) => {
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);

      sethomeCity("");
      sethomepostCode("");

      setnewSubs(newSubArray.filter((u: any) => u.state === value.id));
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeCity(value.name_en);

      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });

          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setmobile(cData?.mobileNumber);
          sethomeDistrict(
            cData?.homeDistrict !== null ? cData?.homeDistrict : ""
          );
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(
            cData?.homepostCode !== null ? cData?.homepostCode : ""
          );
          setuserId(cData?.id);
          setpayDisable(false);
          setstDisable(false);

          getDistricts()
            .then((res) => {
              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0);

              setnewSubs(
                newSubArray.filter(
                  (u: any) =>
                    u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)
                )
              );

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const getCart = () => {
    setlopen(true);
    getCartList()
      .then((res) => {
        let cartTotalPrice = 0;
        // const abc = res.data?.map((e: any) => {
        //   let price = e.specialprice ? e.specialprice : e.mrp;
        //   const finalProductPrice = parseFloat(price).toFixed(2);

        //   cartTotalPrice += parseFloat(finalProductPrice) * e.qty;
        //   // m = m + e.qty * e.discountedprice;
        // });
        setArray(res.data?.cart_items);
        setTotal(res.data?.total);
        setcartotal(res.data?.total);
        setSubTotal(res.data?.sub_total);
        setTotal_Mrp_Value(res.data?.total_mrp_value);
        setTotal_Saving(res.data?.total_saving);
        setlopen(false);
        CartCount().then((res) => {
          setCart(res);
        });

        // const newCart = res.data?.map((item: any, index: any) => {
        //   return {
        //     ...item
        //     // images : JSON.parse(item.images),
        //   };
        // });

        // const gCart = newCart?.map((item: any, index: any) => {
        //   getProductById2(item?.id)
        //     .then((res) => {
        //       delete res.data[0].images;
        //       delete res.data[0].description;
        //       delete res.data[0].options;
        //       delete res.data[0].priceDetails;
        //       delete res.data[0].imgArray;

        //       let unitPrice: any = 0;
        //       let discount = item.discount;
        //       let cartQty = item.qty >= 1 ? item.qty : 1;

        //       // const discountedPrice = cartItem.discountedprice;
        //       // const finalProductPrice = cartItem.unitprice?.toFixed(2);
        //       // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);

        //       // this.setState(
        //       //   {
        //       //     product: res.data[0],
        //       //   },
        //       //   () => {
        //       //     console.log(">>>>>>>>>>>>>>", this.state.product);
        //       //   }
        //       // )
        //       newCart[index].productDetails = res.data[0];
        //       newCart[index].productDetails.qty = item.qty;
        //       newCart[index].productDetails.productPrice = item.unitprice;
        //       // newCart[index].productDetails.id = item.productId;
        //       newCart[index].productDetails.design = item.design;
        //     })
        //     .catch((error) => {
        //       console.log("Error", error.response);
        //       // this.setState({ product: "" });
        //     });

        //   // newItemList[index].productDetails = this.state.product;
        //   // newItemList[index].qty = item.qtyOrderd;
        //   // newItemList[index].id = item.productId;
        //   // delete newItemList[index].discountedprice;
        //   // delete newItemList[index].qtyOrderd;
        //   // delete newItemList[index].images;
        // });

        // console.log("New Item >>", newCart);
        // setcartproducts(newCart);
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        setArray([]);
        setTotal(0);
        setcartotal(0);
        setlopen(false);
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  };

  const renderLeft = (isActive: any = true) => {
    return (
      <div className="space-y-8 bg-[#f1f1f0]">
        {/* <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div> */}

        <div id="ShippingAddress" className="scroll-mt-24">
          {/* <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          /> */}
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <div className="sm:ml-4">
                <h3 className=" text-black dark:text-slate-300 font-berthold font-normal text-xl flex ">
                  <span className="">Billing Details</span>
                </h3>
                {/* <div className="font-semibold mt-1 text-xs">
              <span className="">
                St. Paul's Road, Norris, SD 57560, Dakota, USA
              </span>
            </div> */}
              </div>
              {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-xs font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary> */}
            </div>
            <div
              className={`border-t border-white dark:border-slate-700 px-14 py-7 space-y-4 sm:space-y-6 ${
                isActive ? "block" : "hidden"
              }`}
            >
              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                <div>
                  <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                    First name
                  </Label>
                  <Input
                    className="mt-1.5 font-GlacialIndifference"
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                    value={firstName}
                  />
                  {errfirstName && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                    Last name
                  </Label>
                  <Input
                    className="mt-1.5 font-GlacialIndifference"
                    onChange={(e) => {
                      setlasttName(e.target.value);
                      seterrlasttName(false);
                    }}
                    value={lastName}
                  />
                  {errlastName && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                <div className="max-w-lg">
                  <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                    Email ID
                  </Label>
                  <Input
                    className="mt-1.5 font-GlacialIndifference"
                    type={"email"}
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                    value={email}
                  />
                  {erremail && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>

                <div className="max-w-lg">
                  <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                    Contact No
                  </Label>
                  <Input
                    className="mt-1.5 font-GlacialIndifference"
                    placeholder={"+94 xxx"}
                    type={"tel"}
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile("");
                    }}
                    value={mobile}
                  />
                  {errmobile && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      {errmobile}
                    </div>
                  )}
                </div>
              </div>

              {/* ============ */}

              {/* <div>
                 <Label className="text-[#010a4f] text-[20px] tracking-wide  ">Order Type</Label>
                <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                
                  <Radio
                    label={`<span class="text-xs font-medium">Delivery </span>`}
                    id="Address-type-home"
                    name="Address-type"
                    checked={delType === "delivery"}
                    onChange={(e) => {
                      setdelType("delivery");
                    }}
                  />
                  <Radio
                    label={`<span class="text-xs font-medium">Pick Up </span>`}
                    id="Address-type-office"
                    name="Address-type"
                    checked={delType === "pickup"}
                    onChange={(e) => {
                      setdelType("pickup");
                    }}
                  />
                </div>
              </div> */}

              {delType === "delivery" && (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                    <div className="max-w-lg">
                      <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                        Unit No
                      </Label>
                      <Input
                        className="mt-1.5"
                        type={"text"}
                        onChange={(e) => {
                          setunitno(e.target.value);
                          seterrunitno(false);
                        }}
                        value={unitno}
                      />
                      {errunitno && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                    <div className="max-w-lg">
                      <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                        Street Name
                      </Label>
                      <Input
                        className="mt-1.5"
                        type={"text"}
                        onChange={(e) => {
                          setstreetname(e.target.value);
                          seterrstreetname(false);
                        }}
                        value={streetname}
                      />
                      {errstreetname && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                  </div>

                  {/* ============ */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                    <div>
                      <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                        District
                      </Label>
                      {/* <Input className="mt-1.5" defaultValue="Norris" /> */}
                      <Autocomplete
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        defaultValue={{ name_en: homeDistrict }}
                        value={{ name_en: homeDistrict }}
                        // id="combo-box-demo"
                        options={allHDistricts}
                        getOptionLabel={(option) => option.name_en}
                        onChange={(event, value) => {
                          handleChangeHdist(event, value);
                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input
                              {...params.inputProps}
                              className="mt-1.5"
                              placeholder="Select District"
                            />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px"
                            // border: '1px solid white'
                          }
                        }}
                      />

                      {errhomeDistrict && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                    <div>
                      <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                        City
                      </Label>
                      {/* <Input className="mt-1.5" defaultValue="Texas" /> */}

                      <Autocomplete
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        defaultValue={{ name_en: homeCity }}
                        value={{ name_en: homeCity }}
                        // id="combo-box-demo"
                        options={allHCities}
                        // getOptionLabel={(option) => option?.fields?.scc_name}
                        // getOptionLabel={(option) => option?.locality}
                        getOptionLabel={(option) => option?.name_en}
                        onChange={(event, value) => {
                          handleChangeHcity(event, value);
                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input
                              {...params.inputProps}
                              className="mt-1.5"
                              placeholder="Search City"
                            />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px"
                            // border: '1px solid white'
                          }
                        }}
                      />
                      {errhomeCity && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                  </div>

                  {/* ============ */}

                  <div className="w-full">
                    <div>
                      <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                        Comments
                      </Label>
                      <Textarea
                        className="mt-1.5"
                        value={notes}
                        placeholder="Enter Comments"
                        onChange={(e) => {
                          setnotes(e.target.value);
                          // seterrhomepostCode(false);
                        }}
                      />
                      {/* {errhomepostCode && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )} */}
                    </div>
                  </div>
                </>
              )}

              {/* ============ */}

              {/* ============ */}
              {/* <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={onCloseActive}
            >
              Save and next to Payment
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancel
            </ButtonSecondary>
          </div> */}
            </div>
          </div>
        </div>

        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };

  React.useEffect(() => {
    if (localStorage.getItem("tempUser")) {
      settempUser(localStorage.getItem("tempUser"));
    } else {
      settempUser(null);
    }
  }, []);
  const onSubmit2 = async (e?: any, type?: any) => {
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    // if (country === "") {
    //   seterrcountry(true);
    // }
    // if (homeAddress === "") {
    //   seterrhomeAddress(true);
    // }
    if (unitno === "") {
      seterrunitno(true);
    }
    if (streetname === "") {
      seterrstreetname(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }

    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(currUser.firstName)),
      lastName: upperFirst(lowerCase(currUser.lastName)),
      mobileNumber: currUser.mobileNumber,
      email: currUser.email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      // homeAddress: homeAddress,
      unitno: unitno,
      streetname: streetname,
      homeCity: homeCity,
      password: "",

      homeDistrict: homeDistrict,
      // homepostCode: homepostCode,
      note: notes
    };

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      // country !== "" &&
      phoneRegex.test(mobile) &&
      ((unitno !== "" &&
        streetname !== "" &&
        homeCity !== "" &&
        homeDistrict !== "" &&
        // homepostCode !== "" &&
        delType === "delivery") ||
        delType === "pickup")
      // (password !== "" ? repassword !== "" : firstName !== "") &&
    ) {
      setlopen(true);

      // if (!tempUser) {
      //   EditUserQuery(obj)
      //     .then((res) => {
      //       // setlopen(false);
      //       // setOpenToast(true);
      //       // setColor(COLORS.Primary);
      //       // setAlertClass("success");
      //       // setMsg("Update Successful");
      //       getCurrentUser();

      //       // seteDisable(true);

      //       // history.push("/");
      //       // window.location.reload();
      //     })
      //     .catch((error) => {
      //       // setlopen(false);
      //       console.log("Error", error.response);

      //       // setOpenToast(true);
      //       // setColor(COLORS.Orange);
      //       // setAlertClass("error");
      //       // setMsg(error.response.data.message);
      //     });
      // } else {
      //   let obj = {
      //     data: {
      //       firstName: upperFirst(lowerCase(firstName)),
      //       lastName: upperFirst(lowerCase(lastName)),
      //       mobileNumber: mobile,
      //       email: email,
      //       password: "",
      //       clientType: "individual",
      //       registered: 0,
      //       userId: tempUser,
      //       unitno : unitno,
      //       streetname : streetname,
      //       // homeAddress: homeAddress,
      //       homeCity: homeCity,
      //       homeDistrict: homeDistrict
      //       // homepostCode: homepostCode
      //     }
      //   };

      //   // RegisterUser(obj.data)
      //   //   .then((res) => {
      //   //     // setlopen(false);
      //   //     // addToast(`Registration Successful `, "success");
      //   //     // history.push("/login");
      //   //     // setaKey("login")
      //   //     // setuserId(res.data[0]);
      //   //     // setOpenToast(true);
      //   //     // setColor(COLORS.Primary);
      //   //     // setAlertClass("success");
      //   //     // setMsg("Register Successful");
      //   //     // setTimeout(() => {
      //   //     //   setlopen(false);
      //   //     //   //  setOpen(true);
      //   //     //   history.push("/login-register");
      //   //     //   // window.location.reload();
      //   //     //   // setProgress(false);
      //   //     // }, 2000);
      //   //     // history.push("/");
      //   //     // window.location.reload();
      //   //   })
      //   //   .catch((error) => {
      //   //     setlopen(false);
      //   //     // addToast(error.response.data.message, "warning");

      //   //     // console.log("Error", error.response);
      //   //     // setProgress(false);
      //   //     // setOpenToast(true);
      //   //     // setColor(COLORS.Orange);
      //   //     // setAlertClass("error");
      //   //     // setMsg(error.response.data.message);
      //   //   });
      // }

      let orderMyNo: any = 10000;
      let d = moment().format("lll");

      await getOrderDetails()
        .then((res) => {
          orderMyNo = parseInt(res.data[res.data.length - 1]?.orderNo) + 1;
        })
        .catch((error) => {
          console.error("order history error", error.response);
        });

      const emailTem = await EMAIL_TEMPLATE({
        customerName: `${firstName} ${lastName}`,
        invoiceNo: `${orderMyNo}`.trim().toString(),
        pdfName: `${firstName}_${`${orderMyNo}`.trim().toString()}`,
        deliverydate: deliveryDate
      });

      const emailTem2 = await EMAIL_TAMPLETE2({
        customerName: `${firstName} ${lastName}`,
        address: unitno,
        address2: `${homeDistrict}`,
        address3: `${homeCity}`,
        address4: ``,
        date: moment(new Date()).format("l"),
        invoiceNo: `${orderMyNo}`.trim().toString(),
        tax: 0,
        amount: SubTotal,
        Total_Mrp_Value: Total_Mrp_Value,
        Total_Saving: Total_Saving,
        MESSAGE: "",
        iUsers: array,
        currency: "LKR",
        delType: delType,
        mobileNumber: mobile
      });

      // const emailTem = await EMAIL_TAMPLETE({
      //   date: d,
      //   currency: "$",
      //   amount: Subtotal,
      //   orderNo: orderMyNo,
      //   data: cartproducts,
      //   IMAGE_URL: IMAGE_SERVER_URL_DEV,
      //   MESSAGE: "",
      // });

      const shippingcost = 16.95;

      const obj2 = {
        // productId: item?.productId,
        orderNo: `${orderMyNo}`.trim().toString(),
        invoiceNo: `${orderMyNo}`.trim().toString(),
        userId: !tempUser ? userId : tempUser,
        userName: firstName,
        email: email,
        qty: array[0]?.qty,
        productName: array[0]?.productName,
        productId: array[0]?.id,
        itemTotal: array[0]?.sub_total,
        shippingAddress:
          unitno + "," + streetname + "," + homeCity + "," + homeDistrict,
        billingAddress:
          unitno + "," + streetname + "," + homeCity + "," + homeDistrict,
        productPrice: array[0]?.mrp,
        specialprice: array[0]?.specialprice,
        buyone: array[0]?.buyone,
        // shippingCost: shippingcost,
        amountPayable: parseFloat(Total).toFixed(2),
        payment: "Card",
        date: moment().format("lll"),
        productDetails: array,
        image: array,
        customerDetails: {
          customer: currUser,
          billing: obj,
          shipping: obj
        },
        company_id: currUser.company_id,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: mobile,
        billingState: homeDistrict,
        shippingState: homeDistrict,
        billingSuburb: homeCity,
        shippingSuburb: homeCity,
        totalMrpValue: Total_Mrp_Value,
        totalSaving: Total_Saving,
        deliveryDate: deliveryDate,
        // billingpostCode: homepostCode,
        // shippingpostCode: homepostCode,
        emailBody: emailTem,
        pdfName: `${firstName}_${`${orderMyNo}`.trim().toString()}`,
        emailTemplate: emailTem2,
        subject: "",
        shippingCost: delType === "delivery" ? 0 : 0,
        payType: type
      };

      OrderSave(obj2)
        .then((res) => {
          // addToast("Your Order Successfully Sent", { appearance: "success", autoDismiss: true });
          if (res.data !== undefined) {
            history.push("/shop");
          } else {
            history.push("/cart");
          }
          // this.setState(
          //   {
          //     color: "#635bff",
          //     alertClass: "success",
          //     msg: "Your Order Successfully Sent",
          //     open_toast: true,
          //   },
          //   () => {
          //     setTimeout(() => {
          //       this.props.Step4Switch();
          //     }, 2000);
          //   }
          // );
        })
        .catch((error) => {
          setlopen(false);
          addToast(error.response.data.message, {
            appearance: "warning",
            autoDismiss: true
          });
          console.log("Error", error.response);
        });
    } else {
      // scrollElement();
    }
  };
  console.log(deliveryDate);
  return (
    <div className="nc-CheckoutPage">
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Checkout || Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      <div className="font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={checkouthero} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="container space-y-6 py-10 md:py-16 lg:space-y-6">
          {array.length > 0 ? (
            <>
              <div className="flex flex-col lg:flex-row lg:justify-between">
                <div className="w-full lg:w-[50%] xl:w-[50%]  ">
                  {renderLeft()}
                </div>

                <div className="w-full lg:w-[36%] ">
                  <h3 className="text-lg font-GlacialIndifference font-semibold ">
                    Order Summary
                  </h3>
                  {/* <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                  {array.map(renderProduct2)}
                </div> */}

                  <div className="mt-10 text-xs text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                    <div className="mt-7 text-xs text-slate-500 dark:text-slate-400 ">
                      {array.map((item: any, index: any) => {
                        // Calculate the quantity the user needs to pay for based on the "Buy One Get One Free" rule
                        const paidQty = item?.buyone
                          ? Math.ceil(item.qty / 2)
                          : item.qty;

                        return (
                          <div
                            key={index}
                            className="flex font-GlacialIndifference text-[#9f99a1] flex-col"
                          >
                            {/* Main Product Row */}
                            <div className="flex justify-between pb-1 pt-2">
                              <div className="w-7/12 flex items-center gap-1">
                                <span>{item?.productName}</span> (
                                <span
                                  className={`${
                                    item?.specialprice ? "line-through" : ""
                                  }`}
                                >
                                  {parseFloat(item.mrp).toFixed(2)}
                                </span>
                                {item?.specialprice && (
                                  <span>
                                    {parseFloat(item.specialprice).toFixed(2)}
                                  </span>
                                )}
                                <span>) x {paidQty}</span>
                              </div>
                              <span className="font-normal  dark:text-slate-200 whitespace-nowrap">
                                LKR {(item?.total_price).toFixed(2)}
                              </span>
                            </div>

                            {/* Free Product Row if Buy One Get One */}
                            {item?.buyone === 1 && item.qty > 1 && (
                              <div className="flex justify-between pb-1 pt-2">
                                <div className="w-7/12 flex items-center gap-1">
                                  <span>{item?.productName} (Free)</span> x{" "}
                                  {Math.floor(item.qty / 2)}
                                </div>
                                <span className="font-normal  dark:text-slate-200 whitespace-nowrap">
                                  LKR 0.00
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {/* <div className="mt-4 flex justify-between py-0.5">
                      <span>Subtotal</span>
                      <span className="font-normal text-slate-900 dark:text-slate-200">
                        {`LKR ${Total.toFixed(2)}`}
                      </span>
                    </div> */}

                    {/* {delType === "delivery" && (
                      <div className="mt-4 flex justify-between pb-2.5">
                        <span>Shipping</span>
                        <span className="font-normal text-slate-900 dark:text-slate-200">
                          {`LKR ${16.95}`}
                        </span>
                      </div>
                    )} */}

                    {/* <div className="mt-4 flex justify-between pb-2.5">
                      <span>Tax (10% GST)</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        {`$ ${((Total) * 10 / 100).toFixed(2)}`}
                      </span>
                    </div> */}

                    {/* <div className="flex justify-between py-2.5">
                <span>Shipping estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $5.00
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $24.90
                </span>
              </div> */}
                    <div className="flex justify-between font-GlacialIndifference font-bold text-[#545454] dark:text-slate-200 text-sm pt-2">
                      <span>Sub Total</span>
                      {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                      <span>{`LKR ${Total.toFixed(2)}`}</span>
                    </div>
                    <div className="flex justify-between  font-GlacialIndifference  font-bold text-[#545454] dark:text-slate-200 text-sm pt-2">
                      <span>Grand Total</span>
                      <span> {`LKR ${Total.toFixed(2)}`}</span>
                    </div>

                    <div className="flex justify-between font-GlacialIndifference   font-semibold text-[#767178] italic dark:text-slate-200 text-sm pt-2">
                      <span>Total Mrp Value</span>
                      {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                      <span>{`LKR ${Total_Mrp_Value.toFixed(2)}`}</span>
                    </div>
                    <div className="flex justify-between font-GlacialIndifference   font-bold  text-[#02871a] italic dark:text-slate-200 text-sm pt-2">
                      <span>Total Saving</span>
                      {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                      <span>{`LKR ${Total_Saving.toFixed(2)}`}</span>
                    </div>
                  </div>

                  {/* {payDisable === true &&
                    ((homeDistrict !== "" && homeDistrict !== "Victoria") ||
                      (homeDistrict === "Victoria" && homeCity !== "")) &&
                    delType === "delivery" && (
                      <div className="p-5 w-full bg-[#008500] mt-5 flex justify-center">
                        <span className="text-white text-center">
                          Please contact us for customize quotation for delivery
                        </span>
                      </div>
                    )} */}
                  <div className=" bg-[#d9d9d9] font-GlacialIndifference p-5 flex flex-col rounded-lg mt-5 gap-1 ">
                    <div className=" text-sm font-semibold tracking-wider text-[#545454] ">
                      Delivery Date
                    </div>
                    <div className=" text-xs text-[#545454] ">
                      Your order is expected to be delivered on {deliveryDate}.
                      However, you will receive an SMS notification confirming
                      the delivery date.
                    </div>
                  </div>
                  {(payDisable === false || delType === "pickup") && (
                    <>
                      {/* <ButtonPrimary
                        className="mt-8 w-full"
                        onClick={(e?: any) => {
                          onSubmit2(e, "paypal");
                        }}
                      >
                        Pay with Paypal
                      </ButtonPrimary> */}

                      <div className=" w-full flex justify-center items-center">
                        <div
                          className="mt-8 cursor-pointer w-fit px-20 py-3 font-GlacialIndifference uppercase font-bold disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl "
                          onClick={(e?: any) => {
                            onSubmit2(e, "stripe");
                          }}
                        >
                          Payment
                        </div>
                      </div>
                    </>
                  )}

                  {/* <div className="mt-5 text-xs text-slate-500 dark:text-slate-400 flex items-center justify-center">
                    <p className="block relative pl-5">
                      <svg
                        className="w-4 h-4 absolute -left-1 top-0.5"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V13"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.9945 16H12.0035"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Learn more{` `}
                     
                      <NavLink
                        to={"/shipping"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <a
                          // target="_blank"
                          // rel="noopener noreferrer"
                          // href=""
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Shipping
                        </a>
                        {` `} infomation
                      </NavLink>
                    </p>
                  </div> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No items added to cart
                </p>
              </div>
              <div className="flex mt-6 justify-center items-center">
                <a href="/shop">
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-full flex  flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default CheckoutPage;
