import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import newbackground from "images/newbackground.png";
import login from "images/login.png";
import contacthero from "images/contacthero.png";

import pageBottom from "images/pagebottom.png";
import pagetop from "images/page-top.png";
import Textarea from "shared/Textarea/Textarea";

export interface PageNewContactProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageNewContact: FC<PageNewContactProps> = ({ className = "" }) => {
  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const Login = async (e?: any) => {
    e.preventDefault();

    if (username === "") {
      seterrerrUsername("This Field Required");
    }

    if (username !== "") {
      seterrerrUsername("");
    }

    if (lpass === "") {
      seterrerrLpass("This Field Required");
    }

    if (lpass !== "") {
      seterrerrLpass("");
    }
    // eslint-disable-next-line no-console

    let obj = {
      email: username,
      password: lpass
    };

    // if (username !== "" && lpass !== "") {
    //   // setlOpen(true);
    //   LoginQuery(obj)
    //     .then((res) => {
    //       // setUser("logged");
    //       // setOpenToast(true);
    //       // setColor(COLORS.Primary);
    //       // setAlertClass("success");
    //       // setMsg("Login Successful");

    //       localStorage.clear();

    //       let decoded: any = null;

    //       decoded = jwt_decode(res?.data);
    //       console.log("logindata", decoded);
    //       if (res?.status) {
    //         localStorage.setItem(
    //           `${window.location.hostname}.login`,
    //           JSON.stringify(res)
    //         );

    //         localStorage.setItem("exprTime", decoded?.exp);
    //         // localStorage.setItem("remember", rememberMe);
    //       }

    //       // history.push("/");
    //       // window.location.reload();

    //       if (decoded.status !== 1) {
    //         setTimeout(() => {
    //           // history.push("/account");
    //           window.location.href = "/account"; // Navigate and reload once
    //         }, 0);
    //       } else {
    //         setTimeout(() => {
    //           // history.push("/");
    //           window.location.href = "/"; // Navigate and reload once
    //         }, 0);
    //       }
    //     })
    //     .catch((error) => {
    //       // setlOpen(false);
    //       console.log(error);

    //       if (error.response.data.statusCode === 401) {
    //         addToast(error.response.data.message, "warning");
    //         // seterrerrLpass(error.response.data.message);
    //       } else if (error.response.data.statusCode === 500) {
    //         addToast(error.response.data.errorMessage, "warning");
    //         // seterrerrUsername(error.response.data.errorMessage);
    //       } else {
    //         addToast(error.response.data.message, "warning");
    //         // setOpenToast(true);
    //         // setColor("red");
    //         // setAlertClass("error");
    //         // setMsg(error.response.data.message);
    //       }
    //     });
    // }
  };

  return (
    <div
      className={`nc-PageNewContact ${className}`}
      data-nc-id="PageNewContact"
    >
      <Helmet>
        <title>Login || Booking React Template</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/login/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/login"
}
    `}</script>
      </Helmet>
      <div className=" mb-24 lg:mb-32 font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={contacthero} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="max-w-xl mx-auto space-y-6 pt-16">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-xs font-medium text-neutral-700 dark:text-neutral-300 sm:text-xs">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xs bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-8 bg-[#f0f0f0] rounded-lg p-10 pt-12"
            onSubmit={Login}
          >
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-semibold dark:text-neutral-200">
                Full Name
              </span>
              <Input
                type="text"
                placeholder=" Full Name"
                 className="mt-1 font-GlacialIndifference"
                // onChange={(e) => {
                //   setusername(e.target.value);
                //   seterrerrUsername("");
                // }}
                // value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-semibold dark:text-neutral-200">
              Employer Name
              </span>
              <Input
                type="text"
                placeholder=" Employer Name"
                 className="mt-1 font-GlacialIndifference"
                // onChange={(e) => {
                //   setusername(e.target.value);
                //   seterrerrUsername("");
                // }}
                // value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-semibold dark:text-neutral-200">
             Email:
              </span>
              <Input
                type="text"
                placeholder="Email"
                 className="mt-1 font-GlacialIndifference"
                // onChange={(e) => {
                //   setusername(e.target.value);
                //   seterrerrUsername("");
                // }}
                // value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-semibold dark:text-neutral-200">
             Contact Number:
              </span>
              <Input
                type="text"
                placeholder="Contact Number"
                 className="mt-1 font-GlacialIndifference"
                // onChange={(e) => {
                //   setusername(e.target.value);
                //   seterrerrUsername("");
                // }}
                // value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-semibold dark:text-neutral-200">
             Message:
              </span>
              <Textarea
               
                placeholder="Type Message"
                className="mt-1 font-GlacialIndifference"
                // onChange={(e) => {
                //   setusername(e.target.value);
                //   seterrerrUsername("");
                // }}
                // value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            {/* <label className="block">
              <span className="flex justify-between items-center text-[#010a4f] text-[22px] ">
                Password
              </span>
              <Input
                type="password"
                 className="mt-1 font-GlacialIndifference"
                placeholder="Password"
                onChange={(e) => {
                  setlpass(e.target.value);
                  seterrerrLpass("");
                }}
                value={lpass}
              />
              {errLpass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errLpass}
                </div>
              )}
              <div className=" flex w-full justify-end">
                <Link
                  to="/reset-password"
                  className="text-xs underline text-[#545454]"
                >
                  Forgot password?
                </Link>
              </div>
            </label> */}
            <ButtonPrimary type="submit">SEND</ButtonPrimary>

            {/* <span className="block text-center text-[#545454] dark:text-neutral-300">
              New user? {` `}
              <Link className="text-[#545454] underline" to="/register">
                Create an account
              </Link>
            </span> */}
          </form>

          {/* ==== */}
        </div>
     
      </div>
      <div className="w-full flex flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default PageNewContact;
